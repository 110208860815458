import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles({
  notFound: {
    minHeight: 'calc(100vh - 275px)',
  }
});

const NotFoundPage = () => {
	const styles = useStyles();

	return (
		<Layout>
			<SEO title="Page Not found" description="Page not found. Try another page to learn how SpeedyDock can help your dry stack marina or boat club." />
			<Box
				alignItems="center"
				className={styles.notFound}
				display="flex"
				pb={8}
				pt={16}
				bgcolor="secondary.light"
				color="primary.main"
				textAlign="center"
			>
				<Container>
					<Typography variant="h3" component="h1" gutterBottom>
						Page Not Found
					</Typography>
					<Typography paragraph>
						The page you were looking for can not be found
					</Typography>
				</Container>
			</Box>
		</Layout>
	);
};

export default NotFoundPage
